import { H2, Image, YStack } from '@mythical/ui'
import { ButtonLink } from 'app/components/primitive/Button'
import MainLayout from 'app/components/structure/Layout/MainLayout'
import { observer } from 'mobx-react-lite'
import React from 'react'

export const NotFoundScreen = observer(() => {
  return (
    <MainLayout
      contentStyles={{
        shadowColor: 'transparent',
        height: '100%',
        jc: 'center',
        ai: 'center',
      }}
    >
      <YStack
        maxWidth={700}
        width="100%"
        mx="auto"
        borderWidth={1}
        borderColor="$primary10"
        borderRadius="$3"
        bg="$secondary10"
        py="$8"
        px="$4"
        jc="center"
        ai="center"
      >
        <Image
          source={require('app/assets/images/404.png')}
          width="100%"
          height={300}
          resizeMode="contain"
          accessibilityLabel="404: Page not found"
        />
        <YStack space="$3" jc="center" ai="center">
          <H2
            textAlign="center"
            color="$primary1"
            textTransform="uppercase"
            size="$7"
            fontWeight="500"
            $gtSm={{
              size: '$8',
              fontWeight: '500',
            }}
          >
            Oops! something’s wrong here
          </H2>
          <ButtonLink href="/">Reload Homepage</ButtonLink>
        </YStack>
      </YStack>
    </MainLayout>
  )
})
